import { render, staticRenderFns } from "./FormieForm.vue?vue&type=template&id=4664965b&scoped=true"
import script from "./FormieForm.vue?vue&type=script&lang=js"
export * from "./FormieForm.vue?vue&type=script&lang=js"
import style0 from "./FormieForm.vue?vue&type=style&index=0&id=4664965b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4664965b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomHeadingContentItem: require('/home/userapp/components/Atom/Heading/HeadingContentItem.vue').default,AtomIcon: require('/home/userapp/components/Atom/Icon.vue').default})
