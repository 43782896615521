
import cloneDeep from 'lodash/cloneDeep'
import flatten from 'lodash/flatten'
import { defineComponent, useContext, ref, unref, useRouter, useFetch } from '@nuxtjs/composition-api'
import { fieldComponentName, fieldVisible } from '~/assets/formie'
import { linkDetails } from '~/assets/link'
import { getLinkRewrites } from '~/assets/craft'

//
// Change formulate `value` into a Formie value
//
function postProcess(fields, value) {
  // A recursive finder that locates nodes eligible for post-processing
  function finder(root) {
    function helper(node, path) {
      if (node === undefined || node === null) {
        return
      }

      if (Array.isArray(node)) {
        for (let index = 0; index < node.length; index++) {
          // Apply recursion
          helper(node[index], [...path, index])
        }
        return
      }

      if (typeof node === 'object') {
        let match
        for (const [key, child] of Object.entries(node)) {
          // Apply recursion
          helper(child, [...path, key])

          match = key.match(/^__(?<type>Field_[a-z]+)(__(?<group>[a-z]+))?__(?<handle>[0-9a-z_]+)$/i)
          if (match) {
            matches.push({ key, node, type: match.groups.type, group: match.groups.group, handle: match.groups.handle })
          }
        }
      }
    }

    const matches = []
    helper(root, [])
    return matches
  }

  // Clone and find eligible nodes
  const clone = cloneDeep(value)
  const matches = finder(clone)

  // Make a map from the fields for easy access
  const fieldMap = Object.fromEntries([
    // Add every top-level field
    ...fields.map((field) => [`__${field.__typename}__${field.handle}`, field]),
    // Add fields nested within Field_Group fields
    ...flatten(
      flatten(
        fields
          .filter((field) => field.__typename === 'Field_Group')
          .map((field) => field.rows.map((row) => row.fields.map((nestedField) => [`__${nestedField.__typename}__${field.handle}__${nestedField.handle}`, nestedField])))
      )
    ),
  ])

  for (const { key, node, type, handle } of matches) {
    switch (type) {
      case 'Field_Agree':
        // For Formulate this is a boolean, while Formie expects a non-empty string for 'true' and an empty string for 'false'
        node[handle] = node[key] ? fieldMap[key]?.checkedValue || 'True' : ''
        break

      case 'Field_Entries':
        // For Formulate this is either false or a string id, while Formie expects either null or an integer
        node[handle] = node[key] ? Number.parseInt(node[key]) : null
        break

      case 'Field_Hidden':
        // For Formulate this can be any type, while Formie expects null or a string
        node[handle] = node[key] ? `${node[key]}` : null
        break

      case 'Field_Group':
        // For Formulate this is an array with one object, while Formie expects only the object
        node[handle] = node[key]?.[0]
        break

      case 'Field_Name':
        // For Formulate this is an array with one object, while Formie expects only the object (depending on the useMultipleFields setting)
        if (fieldMap[key]?.useMultipleFields) {
          node[handle] = node[key]?.[0]
        } else {
          node[handle] = node[key]
        }
        break

      case 'Field_Number':
        // For Formulate this is a string representing a number, while Formie expects a float, integer, or null
        if (node[key] && node[key].length) {
          if (Number.parseInt(fieldMap[key]?.decimals || '0')) {
            node[handle] = Number.parseFloat(node[key])
          } else {
            node[handle] = Number.parseInt(node[key])
          }
        } else {
          node[handle] = null
        }
        break

      case 'Field_Repeater':
        // For Formulate this is an array with objects, while Formie expects an object with a property 'rows' that contains the array with objects
        node[handle] = { rows: node[key] }
        break

      default:
        // All other values are unmodified
        node[handle] = node[key]
        break
    }
    delete node[key]
  }

  return clone
}

function setupForm(handle, { combinedSizeLimit, enableEntriesField, enableFileUploadField }) {
  const { $graphqlFetch } = useContext()

  // Assign the query results to separate reactive properties
  const title = ref(undefined)
  const settings = ref({})
  const pages = ref([])

  useFetch(async () => {
    const settingsFragment = `
      fragment settingsFragment on FormInterface {
        settings {
          displayFormTitle
          displayCurrentPageTitle
          defaultLabelPosition
          defaultInstructionsPosition

          errorMessageHtml
          redirectUrl
          submitAction
          submitActionFormHide
          submitActionMessageHtml
          submitActionMessageTimeout
          submitActionTab

          loadingIndicator
          loadingIndicatorText
        }
      }`

    const fieldAgreeFragment = `
      fragment fieldAgreeFragment on Field_Agree {
        checkedValue
        defaultState
        name
        uncheckedValue
      }`

    const fieldCheckboxesFragment = `
      fragment fieldCheckboxesFragment on Field_Checkboxes {
        defaultValue
        layout
        options {
          label
          value
        }
      }`

    const fieldDateFragment = `
      fragment fieldDateFragment on Field_Date {
        defaultDate
        includeTime
        minDate
        maxDate
      }`

    const fieldDropdownFragment = `
      fragment fieldDropdownFragment on Field_Dropdown {
        defaultValue
        options {
          label
          value
        }
      }`

    const fieldEmailFragment = `
      fragment fieldEmailFragment on Field_Email {
        defaultValue
      }`

    const fieldEntriesFragment = `
      fragment fieldEntriesFragment on Field_Entries {
        entries {
          id
          title
        }
      }`

    const fieldFileUploadFragment = `
      fragment fieldFileUploadFragment on Field_FileUpload {
        allowedKinds
        limitFiles
        sizeLimit
        sizeMinLimit
        volumeHandle
      }`

    const fieldHeadingFragment = `
      fragment fieldHeadingFragment on Field_Heading {
        headingSize
      }`

    const fieldHiddenFragment = `
      fragment fieldHiddenFragment on Field_Hidden {
        defaultOption
        defaultValue
      }`

    const fieldHtmlFragment = `
      fragment fieldHtmlFragment on Field_Html {
        htmlContent
      }`

    const fieldMultiLineTextFragment = `
      fragment fieldMultiLineTextFragment on Field_MultiLineText {
        defaultValue
        minLength: min
        minType
        maxLength: max
        maxType
      }`

    const fieldNameFragment = `
      fragment fieldNameFragment on Field_Name {
        defaultValue
        useMultipleFields

        prefixEnabled
        prefixLabel
        prefixPlaceholder
        prefixDefaultValue
        prefixRequired
        prefixErrorMessage
        prefixOptions {
          label
          value
        }

        firstNameEnabled
        firstNameLabel
        firstNamePlaceholder
        firstNameDefaultValue
        firstNameRequired
        firstNameErrorMessage

        middleNameEnabled
        middleNameLabel
        middleNamePlaceholder
        middleNameDefaultValue
        middleNameRequired
        middleNameErrorMessage

        lastNameEnabled
        lastNameLabel
        lastNamePlaceholder
        lastNameDefaultValue
        lastNameRequired
        lastNameErrorMessage
      }`

    const fieldNumberFragment = `
      fragment fieldNumberFragment on Field_Number {
        defaultValue
        limit
        minValue: min
        maxValue: max
        decimals
      }`

    const fieldPhoneFragment = `
      fragment fieldPhoneFragment on Field_Phone {
        defaultValue
      }`

    const fieldRadioFragment = `
      fragment fieldRadioFragment on Field_Radio {
        defaultValue
        layout
        options {
          label
          value
        }
      }`

    const fieldSectionFragment = `
      fragment fieldSectionFragment on Field_Section {
        borderStyle
        borderWidth
        borderColor
      }`

    const fieldSingleLineTextFragment = `
      fragment fieldSingleLineTextFragment on Field_SingleLineText {
        defaultValue
        minLength: min
        minType
        maxLength: max
        maxType
      }`

    const fieldProperties = `
      __typename
      id
      inputTypeName
      name
      handle
      labelPosition
      placeholder
      instructions
      instructionsPosition
      required
      errorMessage
      visibility`

    const fieldPropertyFragments = `
      ...fieldAgreeFragment
      ...fieldCheckboxesFragment
      ...fieldDateFragment
      ...fieldDropdownFragment
      ...fieldEmailFragment
      ${enableEntriesField ? '...fieldEntriesFragment' : ''}
      ${enableFileUploadField ? '...fieldFileUploadFragment' : ''}
      ...fieldHeadingFragment
      ...fieldHiddenFragment
      ...fieldHtmlFragment
      ...fieldMultiLineTextFragment
      ...fieldNameFragment
      ...fieldNumberFragment
      ...fieldPhoneFragment
      ...fieldRadioFragment
      ...fieldSectionFragment
      ...fieldSingleLineTextFragment`

    const pagesFragment = `
      fragment pagesFragment on FormInterface {
        title
        pages {
          id
          name
          settings {
            backButtonLabel
            buttonsPosition
            submitButtonLabel
          }
          rows {
            id
            fields: rowFields {
              ${fieldProperties}
              ${fieldPropertyFragments}
              ... on Field_Group {
                rows: nestedRows {
                  fields: rowFields {
                    ${fieldProperties}
                    ${fieldPropertyFragments}
                  }
                }
              }
              ... on Field_Repeater {
                addLabel
                minRows
                maxRows
                rows: nestedRows {
                  fields: rowFields {
                    ${fieldProperties}
                    ${fieldPropertyFragments}
                  }
                }
              }
            }
          }
        }
      }`

    const query = `
      query setupForm($handle: [String]) {
        formieForm(handle: $handle) {
          ...settingsFragment
          ...pagesFragment
        }
      }
      ${settingsFragment}
      ${pagesFragment}
      ${fieldAgreeFragment}
      ${fieldCheckboxesFragment}
      ${fieldDateFragment}
      ${fieldDropdownFragment}
      ${fieldEmailFragment}
      ${enableEntriesField ? fieldEntriesFragment : ''}
      ${enableFileUploadField ? fieldFileUploadFragment : ''}
      ${fieldHeadingFragment}
      ${fieldHiddenFragment}
      ${fieldHtmlFragment}
      ${fieldMultiLineTextFragment}
      ${fieldNameFragment}
      ${fieldNumberFragment}
      ${fieldPhoneFragment}
      ${fieldRadioFragment}
      ${fieldSectionFragment}
      ${fieldSingleLineTextFragment}`

    const data = await $graphqlFetch({ query, variables: { handle }, token: 'formie' })

    // Assign the query results to separate reactive properties
    title.value = data.formieForm.title
    settings.value = { ...data.formieForm.settings, combinedSizeLimit }
    pages.value = data.formieForm.pages
  })

  return {
    pages,
    settings,
    title,
  }
}

function setupSubmit(handle, settings, pages, emit) {
  const router = useRouter()
  const { $config, $graphqlFetch } = useContext()
  const submissionState = ref(undefined)

  const submit = async (formData) => {
    submissionState.value = 'loading'

    const fields = flatten(flatten(unref(pages).map((page) => page.rows.map((row) => row.fields))))
    const mutationName = `save_${handle}_Submission`
    const mutationParameterDefinitions = fields.map((field) => `$${field.handle}: ${field.inputTypeName}`).join(', ')
    const mutationParameters = fields.map((field) => `${field.handle}: $${field.handle}`).join(', ')

    try {
      const query = `
            mutation saveSubmission(${mutationParameterDefinitions}) {
              ${mutationName}(${mutationParameters}) {
                uid
              }
        }`

      const variables = postProcess(fields, formData)
      emit('preSubmission', variables)

      await $graphqlFetch({ query, variables, token: 'formie' })

      // console.log(graphql.data)
      submissionState.value = 'ok'

      // Check if we need to redirect
      const { redirectUrl, submitActionTab, submitAction } = unref(settings)
      const redirect = (targetUrl) => {
        if (!targetUrl) {
          return
        }
        const rewrites = getLinkRewrites($config)
        const { scope, url } = linkDetails('//', targetUrl, { rewrites })
        if (submitActionTab === 'new-tab' || scope === 'outside') {
          window.open(url, submitActionTab === 'new-tab' ? '_blank' : '_self')
        } else {
          router.push({ path: url })
        }
      }
      switch (submitAction) {
        case 'reload':
          window.location.reload()
          break

        case 'entry':
        case 'url':
          redirect(redirectUrl)
          break
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      submissionState.value = 'error'
    }
  }

  return {
    submissionState,
    submit,
  }
}

export default defineComponent({
  props: {
    combinedSizeLimit: { type: Number, required: false, default: undefined },
    enableEntriesField: { type: Boolean, required: false, default: false },
    enableFileUploadField: { type: Boolean, required: false, default: true },
    roundedFull: { type: Boolean, required: false, default: false },
    handle: { type: String, required: true },
    initialValue: { type: Object, required: false, default: () => ({}) },
  },
  emits: ['preSubmission'],
  setup({ combinedSizeLimit, enableEntriesField, enableFileUploadField, handle, initialValue }, { emit }) {
    const { title, settings, pages } = setupForm(handle, { combinedSizeLimit, enableEntriesField, enableFileUploadField })
    const { submissionState, submit } = setupSubmit(handle, settings, pages, emit)
    const currentPage = ref(1)
    const rowIsVisible = (row) => row.fields.every(fieldVisible)

    // todo: Normalize from {handle: value} -> {__Field_Hidden__handle: value}
    const normalizedInitialValue = ref(cloneDeep(unref(initialValue)))

    return {
      currentPage,
      fieldComponentName,
      normalizedInitialValue,
      pages,
      rowIsVisible,
      settings,
      submissionState,
      submit,
      title,
    }
  },
})
